import { createContext, useMemo, useState, useContext, useEffect } from 'react';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from '../../settings/theme';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { createCustomUserTheme } from '../../settings/userTheme';
import { useLocation } from 'react-router-dom';
import logoThemeDark from '../../assets/logos/logo-light.png';
import logoThemeLight from '../../assets/logos/logo-light.png';
import AuthContext from './AuthProvider';

const IntuThemeContext = createContext({});

export const IntuThemeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const { auth } = useContext(AuthContext);

  // User Facing routes
  const userRoutes = ['/app', '/company', '/user/'];

  // Get the current route
  const location = useLocation();

  // Check if the current route matches any user routes or starts with dynamic user paths
  const isMemberRoute = useMemo(() => {
    return userRoutes.some((route) => location.pathname.startsWith(route));
  }, [location.pathname]);

  const [userTheme, setUserTheme] = useState({
    active: false,
    logo_url: '',
    logo_alt: 'IN2TEC',
    primary: '#A8C957',
    secondary: '#333333',
    tertiary: '#A10976',
    contrastColor: '#ffffff',
    textColor: '#000000',
  });

  const { width } = useWindowDimensions();
  const mobileView = useMemo(() => width <= 767, [width]);

  // Sync mode with prefersDarkMode media query
  useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
    if (prefersDarkMode) {
      setUserTheme({
        ...userTheme,
        logo_url: logoThemeDark,
      });
    } else {
      setUserTheme({
        ...userTheme,
        logo_url: logoThemeLight,
      });
    }
  }, [prefersDarkMode]);

  // Set Custom Logo & Theme
  const theme = useMemo(() => {
    const baseTheme = mode === 'dark' ? darkTheme : lightTheme;

    if (userTheme?.active && isMemberRoute) {
      // Set Theme for Non Member Routes or when theme is inactive -> Logo will be updated through CompanyProfile Prrovider
      let theme = createCustomUserTheme(baseTheme, userTheme);
      return responsiveFontSizes(theme);
    } else {
      // Set Logo and Theme for Non Member Routes or when theme is inactive

      if (mode === 'dark') {
        setUserTheme({
          ...userTheme,
          logo_url: logoThemeDark,
        });
      } else {
        setUserTheme({
          ...userTheme,
          logo_url: logoThemeLight,
        });
      }

      let theme = createTheme(baseTheme);
      return responsiveFontSizes(theme);
    }
  }, [
    mode,
    userTheme?.active,
    isMemberRoute,
    auth,
    userTheme?.primary,
    userTheme?.secondary,
    userTheme?.contrastColor,
    userTheme?.textColor,
  ]);

  return (
    <IntuThemeContext.Provider
      value={{
        theme,
        mode,
        setMode,
        userTheme,
        setUserTheme,
        mobileView,
        isMemberRoute,
        logoThemeDark,
        logoThemeLight,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </IntuThemeContext.Provider>
  );
};

export const useIntuTheme = () => useContext(IntuThemeContext);

export default IntuThemeContext;
