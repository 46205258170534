import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import useIntuTheme from '../../hooks/useIntuTheme';

const ManufacturerSubscriptionsComponent = (props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.subscriptions.manufacturers',
  });

  const cardStyles = {
    borderRadius: '10px',
    boxShadow: 3,
    border: '1px solid #e0e0e0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: {
      xs: '100%', // Full height on extra-small screens
      sm: '400px', // 400px on small screens
      md: '500px', // 500px on medium screens
      lg: '600px', // 600px on large screens and above
    },
  };

  const cardContentStyles = {
    padding: 3,
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent="center"
      alignItems="stretch"
      textAlign="center"
      spacing={4}
      sx={{ xs: { padding: '0 1rem 0 1rem' } }}
      {...props}
    >
      {/* Title */}
      <Grid item xs={12}>
        <Typography variant="h1">{t('title')}</Typography>
        <Typography variant="body">{t('description')}</Typography>
      </Grid>

      {/* Subscription 1 */}
      <Grid item xs={11} md={4}>
        <Card
          sx={{
            ...cardStyles,
            backgroundColor: 'grey.light',
          }}
        >
          <CardContent sx={cardContentStyles}>
            <Typography
              gutterBottom
              variant="h6"
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {t('plans.plan_1.title')}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: '600', my: 1, color: 'white' }}
            >
              $599 <span style={{ fontSize: '0.8em' }}>{t('price_label')}</span>
            </Typography>
            <Typography variant="body1" color="white" component="div">
              <Trans
                t={t}
                i18nKey="plans.plan_1.benefits"
                values={{
                  company: props?.companyName
                    ? props.companyName
                    : 'your company',
                }}
                components={{
                  ul: (
                    <ul style={{ paddingLeft: '1.2rem', textAlign: 'left' }} />
                  ),
                  li: <li style={{ wordWrap: 'break-word' }} />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      {/* Subscription 2 */}
      <Grid item xs={11} md={4}>
        <Card sx={{ ...cardStyles, backgroundColor: 'grey.main' }}>
          <CardContent sx={cardContentStyles}>
            <Typography
              gutterBottom
              variant="h6"
              sx={{
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              {t('plans.plan_2.title')}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: '600', my: 1, color: 'white' }}
            >
              $3,499{' '}
              <span style={{ fontSize: '0.8em' }}>{t('price_label')}</span>
            </Typography>

            <Typography variant="body1" color="white" component="div">
              <Trans
                t={t}
                i18nKey="plans.plan_2.benefits"
                values={{
                  company: props?.companyName
                    ? props.companyName
                    : 'your company',
                }}
                components={{
                  ul: (
                    <ul style={{ paddingLeft: '1.2rem', textAlign: 'left' }} />
                  ),
                  li: <li style={{ wordWrap: 'break-word' }} />,
                }}
              />
            </Typography>
          </CardContent>

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 1,
              bgcolor: 'primary.main',
              color: 'white',
              borderRadius: '4px',
            }}
          >
            {t('popular')}
          </Box>
        </Card>
      </Grid>

      {/* Subscription 3 */}
      <Grid item xs={11} md={4}>
        <Card sx={{ ...cardStyles, backgroundColor: 'green.dark' }}>
          <CardContent sx={cardContentStyles}>
            <Typography
              gutterBottom
              variant="h6"
              sx={{
                color: 'white',
                fontWeight: 'bold',
                color: 'white',
              }}
            >
              {t('plans.plan_3.title')}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: '600', my: 1, color: 'white' }}
            >
              10,999{' '}
              <span style={{ fontSize: '0.8em' }}>{t('price_label')}</span>
            </Typography>
            <Typography variant="body1" color="white" component="div">
              <Trans
                t={t}
                i18nKey="plans.plan_3.benefits"
                values={{
                  company: props?.companyName
                    ? props.companyName
                    : 'your company',
                }}
                components={{
                  ul: (
                    <ul style={{ paddingLeft: '1.2rem', textAlign: 'left' }} />
                  ),
                  li: <li style={{ wordWrap: 'break-word' }} />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* OnBoarding Fee */}
      <Grid item xs={11}>
        <Typography variant="body">{t('onboarding')}</Typography>
      </Grid>

      {/* Commission */}
      <Grid item xs={11}>
        <Typography variant="h4">{t('commission.title')}</Typography>
        <Typography variant="body">{t('commission.description')}</Typography>
      </Grid>
    </Grid>
  );
};

export default ManufacturerSubscriptionsComponent;
