import { useContext, useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  Box,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  Slider,
  InputAdornment,
} from '@mui/material';
import { IntuGridStandard } from '../../../mui/dataGrids.js';

import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { classes } from '../../../settings/theme.js';
import StyledInput from '../../../mui/styledInput.js';
import useIntuTheme from '../../../hooks/useIntuTheme.js';

import { LeadContext } from '../../../context/providers/leads/LeadContextProvider.jsx';

const valueText = (value, sign) => {
  let formattedNumber = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedNumber} ${sign}`;
};

// TODO: converge into one calculator, it looks like the only difference is orderValueMarks and CommissionTable logic which can be passed in
export const CommissionCalculator = ({
  transNS = 'translation',
  keyPrefix = 'components.howItWorks.lead_context.manu.commission',
}) => {
  const { leadInfo, revenueCommission, setRevenueCommission } =
    useContext(LeadContext);
  const { mode, mobileView } = useIntuTheme();

  const { t } = useTranslation(transNS, {
    keyPrefix,
  });

  const [initialCommission, setInitialCommission] = useState({
    order_value: 15400,
    commission_order_percent: 0,
    commissions_per_order: 0,
    commission_order: 0,
  });

  const formik = useFormik({
    initialValues: {
      ...initialCommission,
    },
    initialTouched: {},
    values: {},
  });

  const orderValueMarks = [
    {
      value: 100_000,
      label: mobileView ? '100K' : '100,000 US$',
    },
    {
      value: 250_000,
      label: mobileView ? '250K' : '250,000 US$',
    },
    {
      value: 500_000,
      label: mobileView ? '500K US$' : '500,000 US$',
    },
    {
      value: 1_000_000,
      label: '1 Mil. US$',
    },
  ];

  const calculateCommission = (value) => {
    const orderValue = value;
    let brackets = revenueCommission.commissions;

    let payout = 0;
    let totalPayout = 0;
    let baseValue = 0;
    let remainder = value || 0;

    // Caluclate per order commission
    let updatedCommissionSchedule = [...revenueCommission.commission_schedule];
    let commissionPaymentAmount = [];

    for (let i = 0; i < brackets.length; i++) {
      const bracket = brackets[i];

      if (remainder > bracket.maxAmount && bracket.maxAmount !== null) {
        if (i === 0) {
          baseValue = bracket.maxAmount;
        } else {
          baseValue = bracket.maxAmount - brackets[i - 1].maxAmount;
        }
        remainder -= baseValue;
      } else {
        baseValue = brackets[i - 1]?.remainder;
        remainder = 0;
      }

      payout = baseValue > 0 ? baseValue * bracket.rate : 0;
      payout = Math.round((payout + Number.EPSILON) * 100) / 100;

      totalPayout += payout;

      // NEW
      bracket.remainder = remainder;
      bracket.payout = payout;
    }

    // Update Commission Payment
    for (let i = 0; i < updatedCommissionSchedule.length; i++) {
      const element = updatedCommissionSchedule[i];
      if (commissionPaymentAmount[i] > '') {
        element.amount = commissionPaymentAmount[i];
      } else {
        element.amount = '';
      }
    }
    const commissionOrder = totalPayout;
    const commissionOrderPercent =
      commissionOrder > 0 ? commissionOrder / orderValue : 0;

    setRevenueCommission({
      ...revenueCommission,
      commission_order_percent: commissionOrderPercent,
      commission_order: commissionOrder,
    });
  };

  function CommissionTable(props) {
    let leadType = leadInfo.lead_type;
    let brackets = props.CommissionTable;

    const columns = [
      {
        field: 'maxAmount',
        align: 'left',
        flex: 1,
        headerName: mobileView
          ? t('table.column1_header_mobile')
          : t('table.column1_header'),
        valueFormatter: (params) => {
          let formattedValue;
          if (params?.value > 0) {
            formattedValue = new Intl.NumberFormat('en-US').format(
              params.value,
            );
          } else {
            formattedValue = '>';
          }
          return `${formattedValue} US$`;
        },
        editable: (params) => {
          return params.value !== null;
        },
        preProcessEditCellProps: (params) => {
          const value = Number(params.props.value); // Convert to a number
          const isValid = !isNaN(value) && value >= 0; // Example: Check for a valid, non-negative number
          return { ...params.props, error: !isValid }; // Set error if invalid
        },
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 120 : 150,
      },
      {
        field: 'rate',
        type: 'number',
        align: 'left',
        headerName: mobileView
          ? t('table.comm_percent_mobile')
          : t('table.comm_percent'),
        editable: true,
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 30 : 150,
        valueFormatter: (params) => `${params.value * 100} %`, // Converts value to percentage with a `%` sign
        preProcessEditCellProps: (params) => {
          const value = Number(params.props.value / 100);
          // Validate the input (e.g., ensure it's a number)
          return { ...params.props, error: isNaN(value) };
        },
      },
      {
        field: 'payout',
        type: 'number',
        align: 'right',
        headerName: t('table.amount'),
        valueFormatter: (params) => {
          const formattedValue = new Intl.NumberFormat('en-US').format(
            params.value,
          );
          return `${formattedValue} US$`;
        },
        editable: false,
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 130 : 200,
      },
    ];

    // State to track the rows
    const [rows, setRows] = useState(brackets);

    // Function to handle cell updates
    const handleProcessRowUpdate = (newRow, oldRow) => {
      // Ensure numeric fields are converted to numbers
      const updatedRow = {
        ...newRow,
        maxAmount: newRow.maxAmount !== null ? Number(newRow.maxAmount) : null,
      };

      // Update the state with the updated row
      const updatedRows = rows.map((row) =>
        row._id === updatedRow._id ? updatedRow : row,
      );
      setRows(updatedRows);
      setRevenueCommission({ ...revenueCommission, commissions: updatedRows });
    };

    return (
      <Box width="100%">
        <IntuGridStandard
          density="compact"
          disableColumnMenu={true}
          processRowUpdate={handleProcessRowUpdate}
          isCellEditable={(params) => {
            if (params.field === 'maxAmount') {
              return params.value != null;
            }
            return true;
          }}
          getRowId={(row) => row._id}
          rows={revenueCommission.commissions}
          columns={columns}
          disableRowSelectionOnClick
          disableSelectionOnClick
          hideFooterSelectedRowCount
          hideFooter
        />
      </Box>
    );
  }

  useEffect(() => {
    calculateCommission(initialCommission.order_value);
    // setInitialCommission(result);
  }, [i18n.language, revenueCommission.commissions]);

  return (
    <Grid
      container
      alignItems={{ xs: 'center', md: 'flex-start' }}
      alignContent={{ xs: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 'auto' }}
      spacing={2}
    >
      <Grid item container xs={12}>
        <Formik
          values={formik.values}
          initialValues={formik.initialValues}
          initialTouched={formik.initialTouched}
          errors={formik.errors}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const { values, setFieldValue } = formik;
            return (
              <FormControl fullWidth>
                {/* Commission Calculator */}
                <Stack
                  spacing={5}
                  style={classes.root}
                  sx={{ paddingBottom: '10px' }}
                >
                  {/* Order Value Slider */}
                  <Typography id="product-value-commission" gutterBottom>
                    {t('calculator.order_value_slider')}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <RemoveShoppingCartIcon size="small" />
                    <Slider
                      aria-label="Sale"
                      aria-labelledby="product-value-commission"
                      // defaultValue={initialValues.order_value}
                      getAriaValueText={(value) => valueText(value, 'US$')}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => valueText(value, 'US$')}
                      size="medium"
                      onChange={(e, value) =>
                        setFieldValue('order_value', value)
                      }
                      onChangeCommitted={(e, value) => {
                        calculateCommission(value);
                      }}
                      value={values.order_value}
                      step={500}
                      min={0}
                      max={1000000}
                      marks={orderValueMarks}
                      slotProps={{
                        markLabel: {
                          style: {
                            color: mode === 'light' ? '#333333' : '#ffffff',
                          },
                        },
                      }}
                    />
                    <ShoppingCartIcon size="small" />
                  </Stack>
                  <CommissionTable
                    CommissionTable={revenueCommission.commissions}
                  />
                  <Typography>
                    {t('calculator.order_value_statement')}
                  </Typography>
                  {/* Commission Revenue */}
                  <Stack
                    spacing={2}
                    style={classes.root}
                    sx={{ paddingBottom: '50px' }}
                    direction="row"
                  >
                    {/* Commission per Order % */}
                    <StyledInput
                      disabled={true}
                      id="commission_order_percent"
                      name="commission_order_percent"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t('calculator.sales_commission_label')}
                      type="text"
                      // defaultValue={values.commission_order_percent}
                      value={new Intl.NumberFormat('en-US').format(
                        (
                          revenueCommission.commission_order_percent * 100
                        ).toFixed(2),
                      )}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            '& > p': {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        >
                          %
                        </InputAdornment>
                      }
                    />
                    {/* Commission per Order in US$ */}
                    <StyledInput
                      disabled={true}
                      id="commission_order"
                      name="commission_order"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t('calculator.commission_order_label')}
                      // defaultValue={values.commission_order}
                      value={new Intl.NumberFormat('en-US').format(
                        revenueCommission.commission_order.toFixed(2),
                      )}
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            '& > p': {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        >
                          US$
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Stack>
              </FormControl>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export const InvestorCommissionCalculator = () => {
  const { revenueCommission, setRevenueCommission } = useContext(LeadContext);
  const { mode, mobileView } = useIntuTheme();

  const { t } = useTranslation('translation', {
    keyPrefix: 'context.general.investor.commission',
  });

  const [initialCommission, setInitialCommission] = useState({
    order_value: 15400,
    commission_order_percent: 0,
    commissions_per_order: 0,
    commission_order: 0,
  });

  const formik = useFormik({
    initialValues: { ...initialCommission },
    initialTouched: {},
    values: {},
  });

  const orderValueMarks = [
    {
      value: 100_000,
      label: '100,000',
    },
    {
      value: 500_000,
      label: '500,000',
    },
    {
      value: 1_000_000,
      label: '1,000,000',
    },
    {
      value: 2_000_000,
      label: '2,000,000',
    },
  ];

  const calculateCommission = (value, values) => {
    let brackets = revenueCommission.commissions;
    let orderValue = parseInt(values.order_value);
    let commissionOrderPercent =
      parseInt(values.commission_order_percent) / 100;
    let commissionOrder = parseInt(values.commission_order);
    let payout = 0;
    let totalPayout = 0;
    let baseValue = 0;
    let lastBracket = 0;

    // Caluclate per order commission
    let remainder = orderValue || 0;
    let updatedCommissionSchedule = [...revenueCommission.commission_schedule];
    let commissionPaymentAmount = [];

    for (let i = 0; i < brackets.length; i++) {
      if (
        orderValue > brackets[i].maxAmount &&
        brackets[i].maxAmount !== null
      ) {
        if (i === 0) {
          baseValue = brackets[i].maxAmount;
        } else {
          baseValue = brackets[i].maxAmount - brackets[i - 1].maxAmount;
        }
      } else {
        baseValue = remainder;
      }
      payout = baseValue * brackets[i].intu_rate;
      remainder -= baseValue;
      totalPayout += payout;
      lastBracket = brackets[i].maxAmount;

      if (remainder === 0) {
        i = brackets?.length;
      }
      commissionPaymentAmount.push(valueText(payout, 'US$'));
    }

    // Update Commission Schedule
    for (let i = 0; i < updatedCommissionSchedule.length; i++) {
      const element = updatedCommissionSchedule[i];
      if (commissionPaymentAmount[i] > '') {
        element.amount = commissionPaymentAmount[i];
      } else {
        element.amount = '';
      }
    }
    commissionOrder = totalPayout;
    commissionOrderPercent = commissionOrder / orderValue;

    setRevenueCommission({
      ...revenueCommission,
      commission_order_percent: commissionOrderPercent,
    });

    return {
      order_value: orderValue,
      commission_order: valueText(commissionOrder, 'US$'),
      commission_order_percent: valueText(
        (commissionOrderPercent * 100).toFixed(2),
        '%',
      ),
    };
  };

  function CommissionTable(props) {
    let brackets = props.CommissionTable;

    const columns = [
      {
        field: 'maxAmount',
        flex: 1,
        headerName: mobileView
          ? i18n.t(
              'context.general.investor.commission.table.column1_header_mobile',
            )
          : i18n.t('context.general.investor.commission.table.column1_header'),
        editable: true,
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 120 : 150,
      },
      {
        field: 'mfg_rate',
        headerName: mobileView
          ? i18n.t(
              'context.general.investor.commission.table.column2_header_mobile',
            )
          : i18n.t('context.general.investor.commission.table.column2_header'),
        editable: true,
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 30 : 150,
      },
      {
        field: 'intu_rate',
        headerName: i18n.t(
          'context.general.investor.commission.table.column3_header',
        ),
        editable: false,
        resizable: false,
        sortable: false,
        minWidth: mobileView ? 30 : 150,
      },
      {
        field: 'amount',
        headerName: i18n.t(
          'context.general.investor.commission.table.column4_header',
        ),
        resizable: false,
        editable: false,
        sortable: false,
        minWidth: mobileView ? 130 : 200,
      },
    ];

    return (
      <Box sx={{ width: '100%' }}>
        <IntuGridStandard
          density="compact"
          disableColumnMenu={true}
          // isCellEditable={(params) => false}
          getRowId={(row) => row._id}
          rows={brackets}
          columns={columns}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            intu_rate: !mobileView,
          }}
          sx={{
            color:
              mode === 'dark'
                ? 'var(--color-text-dark)'
                : 'var(--color-text-light)',
            '&::-webkit-scrollbar': {
              width: 20,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'orange',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'red',
              borderRadius: 2,
            },
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
          hideFooterSelectedRowCount
          hideFooter
        />
      </Box>
    );
  }

  useEffect(() => {
    let result = calculateCommission(
      formik.initialValues.order_value,
      formik.initialValues,
    );
    setInitialCommission(result);
  }, []);

  return (
    <Grid
      container
      alignItems={{ xs: 'center', md: 'flex-start' }}
      alignContent={{ xs: 'center' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 'auto' }}
      spacing={2}
    >
      <Grid item container xs={12}>
        <Formik
          values={formik.values}
          initialValues={formik.initialValues}
          initialTouched={formik.initialTouched}
          errors={formik.errors}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {(formik) => {
            const { values, setFieldValue, setValues } = formik;
            return (
              <FormControl fullWidth>
                {/* Commission Calculator */}
                <Stack
                  spacing={5}
                  style={classes.root}
                  sx={{ paddingBottom: '10px' }}
                >
                  {/* Order Value Slider */}
                  <Typography id="product-value-commission" gutterBottom>
                    {t('calculator.order_value_slider')}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <RemoveShoppingCartIcon size="small" />
                    <Slider
                      aria-label={t('calculator.order_value_slider_label')}
                      aria-labelledby="product-value-commission"
                      getAriaValueText={(value) => valueText(value, 'US$')}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => valueText(value, 'US$')}
                      size="medium"
                      onChange={(e, value) =>
                        setFieldValue('order_value', value)
                      }
                      onChangeCommitted={(e, value) => {
                        let result = calculateCommission(value, values);
                        setValues({
                          ...values,
                          commission_order: result.commission_order,
                          commission_order_percent:
                            result.commission_order_percent,
                        });
                      }}
                      value={values.order_value}
                      step={500}
                      min={0}
                      max={250000}
                      marks={orderValueMarks}
                    />
                    <ShoppingCartIcon size="small" />
                  </Stack>

                  <CommissionTable
                    CommissionTable={revenueCommission.commissions}
                  />

                  <Typography>
                    {t('calculator.order_value_statement')}
                  </Typography>

                  {/* Commission Revenue */}
                  <Stack
                    spacing={2}
                    style={classes.root}
                    sx={{ paddingBottom: '50px' }}
                    direction="row"
                  >
                    {/* Commission per Order % */}
                    <TextField
                      disabled
                      id="commission_order_percent"
                      name="commission_order_percent"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t('calculator.commission_order_percent_label')}
                      // defaultValue={values.commission_order_percent}
                      value={values.commission_order_percent}
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                    />

                    {/* Commission per Order in US$ */}
                    <TextField
                      disabled
                      id="commission_order"
                      name="commission_order"
                      autoComplete="off"
                      className="form-select-field"
                      aria-describedby="uidnote"
                      variant="outlined"
                      label={t('calculator.commission_order_us_label')}
                      value={values.commission_order}
                      type="text"
                      inputProps={{
                        autoComplete: 'off',
                      }}
                    />
                  </Stack>
                </Stack>
              </FormControl>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
